.upperbannerrow {
  margin-top: 120px;
  color: #083f88;
  font-weight: 500;
}
.upperbannerrow span.trademark {
  font-family: "Poppins";
  position: absolute;
  margin-top: -0.6rem;
}

.timerlogo {
  max-width: 120px;
}

.textheading1 {
  color: #00aae4;
  font-family: "Galada", cursive;
  font-size: 40px;
  text-align: left;
  line-height: 0.5;
}
.ofclass {
  color: #00aae4;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
}
.textheading2 {
  color: #FFFFFF;
  position: absolute;
  font-family: "Galada", cursive;
  font-size: 40px;
}

.content-style-b2 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 21px;
  color: #FFFFFF;
  /* text-align: justify;
  hyphens: auto; */
}

.content-style-b3 {
  font-family: "Poppins";
  font-size: 15px;
  color: #FFFFFF;
  font-weight: 400 !important;
  text-align: justify;
  /* hyphens: auto; */
}
.contentcelligence {
  color: #00aae4;
  text-decoration: none;
  font-family: "Poppins";
}

.light-blue-color-text-b2 {
  color: #00aae4;
  text-decoration: none;
  font-family: "Poppins";
  font-weight: 600;
}

.morgan-mobile-desktop-group {
  position: relative;
}

.morgan-mobile-desktop-vector {
  width: 100%;
}

.morgan-desktop-icon {
  z-index: 1;
  position: absolute;
  margin-top: 6%;
  margin-left: 15%;
  max-width: 150px;
}

.morgan-fair-lending-logo-2 {
  z-index: 1;
  position: absolute;
  margin-top: -4.5%;
  margin-left: 29%;
  max-width: 70px;
  opacity: 0.5;
}

.morgan-mobile-desktop-frame {
  left: 0;
  max-width: 400px;
  position: absolute;
}

.bgflower{
  margin-left: -3rem;
  margin-top: -9rem;
  width:60px;
}

.desktop-ellipse-7 {
  position: absolute;
  max-width: 45px;
  margin-top: -9.5%;
  margin-left: 24%;
}

.desktop-ellipse-7-right {
  position: absolute;
  max-width: 30px;
  margin-top: -2%;
  right: 0;
  margin-right: 2%;
}

.desktop-group-1000005585 {
  position: absolute;
  max-width: 50px;
  margin-left: 12%;
}

.desktop-ellipse-9 {
  position: absolute;
  margin-top: 40%;
}

@media screen and (min-width: 840px) and (max-width: 991px) {
  .morgan-desktop-icon {
    margin-left: 12%;
  }
}

@media screen and (max-width: 991px) {
  .timerlogo {
    max-width: 90px;
  }
  .textheading1 {
    font-size: 30px;
  }
  .ofclass {
    font-size: 23.1px;
  }
  .textheading2 {
    font-size: 30px;
  }
  .content-style-b2,
  .content-style-b3 {
    font-size: 15px;
  }
  .morgan-desktop-icon {
    margin-top: 5%;
    margin-left: 15%;
    max-width: 120px;
  }
  .morgan-fair-lending-logo-2 {
    margin-top: -7%;
    margin-left: 29%;
  }
  .morgan-mobile-desktop-frame {
    max-width: 290px;
  }
  .desktop-ellipse-9 {
    max-width: 40px;
  }
  .desktop-ellipse-7-right {
    margin-right: 3%;
  }
  .bannerbg{
    background-image: url("https://d2b7dijo04ypct.cloudfront.net/FAL/mobile-bg.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height:60rem;
    }
  .upperbannerrow {
    margin-top: 80px !important;

  }
}

@media screen and (min-width: 1262px) and (max-width: 1439.7px) {
  .morgan-desktop-icon {
    margin-top: 5%;
    margin-left: 12%;
  }
  .morgan-fair-lending-logo-2 {
    margin-top: -4%;
    margin-left: 34%;
  }
  .desktop-ellipse-7 {
    margin-top: -9.5%;
  }
  .desktop-ellipse-7-right {
    max-width: 50px;
    margin-right: 4%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599.7px){
  .morgan-desktop-icon {
    margin-top: 4%;
    margin-left: 11%;
  }
  .morgan-fair-lending-logo-2 {
    margin-top: -4%;
    margin-left: 34%;
  }
  .desktop-ellipse-7 {
    margin-top: -9.5%;
  }
  .desktop-ellipse-7-right {
    max-width: 50px;
    margin-right: 4%;
  }
}

@media screen and (min-width: 2560px) {
  .morgan-desktop-icon {
    margin-top: 4%;
    margin-left: 11%;
  }
  .morgan-fair-lending-logo-2 {
    margin-top: -4%;
    margin-left: 34%;
  }
  .desktop-ellipse-7-right {
    max-width: 50px;
    margin-right: 4%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1156px) {
  .morgan-desktop-icon {
    margin-top: 5%;
    margin-left: 13.5%;
  }
}

@media screen and (min-width: 1600px) {
  .morgan-desktop-icon {
    margin-top: 4% !important;
    margin-left: 9.8% !important;
  }
}

@media screen and (min-width: 992px) {
.bannerbg{
background-image: url("https://d2b7dijo04ypct.cloudfront.net/FAL/desktop-bg.webp");
background-size: 100% 100%;
background-repeat: no-repeat;
height:40rem;
}
}


@media screen and (min-width: 992px) and (max-width: 1199px) {
  .content-style-b3{
width: 550px;
  }
}
@media screen and (min-width: 360px) and (max-width: 399.7px) {
  .bannerbg{
    height:55.25rem;
    }
}

@media screen and (min-width: 400px) and (max-width: 429.7px) {
  .bannerbg{
    height:51rem;
    }
}
@media screen and (min-width: 430px) and (max-width: 575.7px) {
  .bannerbg{
    height:48rem;
    }
}

@media screen and (min-width: 576px) and (max-width: 767.7px) {
  .bannerbg{
    height:43rem;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .bannerbg{
    height:60rem;
    }
    
  .content-style-b2,
  .content-style-b3 {
    font-size: 25px;
  }

}


@media screen and (min-width: 1200px) {
.bannerbg {
  height: 43rem;
}
.upperbannerrow {
  margin-top: 130px !important;
}
}
@media screen and (min-width: 1400px) {
  .content-style-b3{
font-size: 18px;
  }
  .content-style-b2{
    font-size: 24px;
  }
  .bannerbg {
    height: 48rem;
  }
  .upperbannerrow {
    margin-top: 150px !important;
  }

}


@media screen and (min-width: 1920px) {
  .bannerbg{
    height:44rem !important;
    }
    .upperbannerrow{
  margin-top: 180px;
    }
  }