.text-right {
    text-align: right;
}
span.trademark {
    font-family: "Poppins";
    position: absolute;
  }
#mobileView {
    font-family: "Poppins";
    font-size: 16px;
}
#mobileView h1 {
    line-height: 0.75;
}
#mobileView a {
    text-decoration: none;
}
#mobileView .bg-container {
    position: relative;
}
#mobileView .overlay-bg-image {
    position: absolute;
    top:0;
    left:0;
}
#mobileView .body-container {
    position: absolute;
    top:0;
}
#mobileView .mobile-sunwest-icon {
    text-align: left;
}
#mobileView .home-fair-logo {
    max-width: 96px;
}
#mobileView .home-text {
    color:#00aae4;
    font-family: 'Galada' !important;
    font-weight: 400;
    font-size: 36.6px;
}
#mobileView .of-text {
    color:#00aae4;
    font-weight: 600;
    font-size: 16.3px;
    margin-top: 0.25rem;
    margin-left: 0.5rem;
}
#mobileView .fair-lending-text {
    color:#fff;
    font-family: 'Galada' !important;
    font-weight: 400;
    font-size: 36.6px;
}

#mobileView .ellipse-6 {
    background-color: #00aae44c;
    border-radius: 13.8px/13.55px;
    height: 27px;
    position: absolute;
    top: 77px;
    width: 28px;
    left: 75%;
}
#mobileView .ellipse-23 {
    background-color: #00aae433;
    border-radius: 7.07px/6.94px;
    height: 14px;
    position: absolute;
    top: 108px;
    width: 14px;
    left: 82%;
} 
#mobileView .ellipse-24 {
    background-color: #00aae426;
    border-radius: 6.35px/6.23px;
    height: 12px;
    position: absolute;
    top: 81px;
    width: 13px;
    left: 85%;
}
#mobileView .as-the-home-of-fair {
    color: #fff;
    font-weight: 600;
}
#mobileView .color-cerulean {
    color: #00aae4;
}
#mobileView .normal-text {
    font-style: normal;
    color: #fff;
    font-weight: 400;
}

#mobileView .ellipse-pacman-group {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 3.6px 1.5px;
}
  
#mobileView .ellipse-container {
    align-items: center;
    display: flex;
    min-width: 41px;
}
  
#mobileView .ellipse-15 {
    height: 10px;
    margin-top: 1.15px;
    width: 11px;
}
  
#mobileView .ellipse-16 {
    height: 19px;
    margin-left: 12px;
    width: 18px;
}
  
#mobileView .ellipse-14 {
    height: 23px;
    margin-left: 4.15px;
    margin-top: 6px;
    width: 26px;
}

#mobileView .body-padding {
    padding: 1rem;
}

#mobileView .dots-grid-left {
    left: 0;
    position: absolute;
    margin-top: 45%;
    z-index: 1;
    opacity: 0.5;
}

#mobileView .dots-grid-top-left {
    left: 0;
    position: absolute;
    z-index: 1;
    opacity: 0.5;
    max-width: 15px;
}

#mobileView .dots-grid-right {
    right: 3px;
    position: absolute;
    z-index: 1;
    opacity: 0.5;
    margin-top: -12%;
    max-width: 15px;
}

#mobileView .morgan-mobile-group {
    position: relative;
    left: 0;
}
#mobileView .morgan-mobile-vector {
    width: 100%;
    margin-top: -25%;
}

#mobileView .morgan-icon {
    left: 120px;
    position: absolute;
    margin-top: -30%;
    z-index: 1;
    max-width: 140px;
}

#mobileView .morgan-mobile-frame {
    left: 0;
    position: absolute;
    margin-top: -50%;
    width: 100%;
}

#mobileView .highlight-dots-grid {
    margin-top: -5% !important;
}

#mobileView .unbiased-ellipse {
    position: absolute;
    left: 0;
    margin-top: -25%;
    max-width: 120px;
}

#mobileView .unbiased-ellipse-right {
    position: absolute;
    right: 0;
    margin-top: -60%;
    max-width: 120px;
}

#mobileView .unbiased-ellipse-dots-grid {
    margin-top: 35%;
}

#mobileView .multilingual-ellipse {
    position: absolute;
    left: 0;
    margin-top: -10%;
    max-width: 120px;
}

#mobileView .highlightContent2-ellipse-right {
    position: absolute;
    right: 0;
    margin-top: -40%;
    max-width: 120px;
}

#mobileView .highlight-multilingual-dots-grid {
    margin-top: -7% !important;
}

#mobileView .footer-hr-line {
    height: 10px;
    margin: 0;
    background: #00aae4;
    opacity: 1;
}

@media screen and (max-width: 321px) {
    #mobileView {
        font-size: 13.1px;
    }
    #mobileView .morgan-icon {
        left: 93px;
        margin-top: -32%;
    }
    #mobileView .unbiased-ellipse {
        max-width: 90px;
    }
    #mobileView .unbiased-ellipse-right {
        max-width: 90px;
    }
    #mobileView .multilingual-ellipse {
        max-width: 90px;
    }
    #mobileView .highlightContent2-ellipse-right {
        max-width: 90px;
    }
}

@media screen and (min-width: 375px) {
    #mobileView .home-text {
        font-size: 42.6px;
    }
    #mobileView .of-text {
        font-size: 21.3px;
    }
    #mobileView .fair-lending-text {
        font-size: 42.6px;
    }
    #mobileView .body-padding {
        padding: 1.5rem !important;
    }
    #mobileView .dots-grid-top-left, 
    #mobileView .dots-grid-right {
        max-width: 20px;
    }
}

@media screen and (min-width: 412px) {
    #mobileView .body-padding {
        padding: 2rem !important;
    }
    #mobileView .morgan-icon {
        left: 127px;
        margin-top: -55%;
        max-width: 180px;
    }
    #mobileView .morgan-mobile-frame {
        margin-top: -75%;
    }
    #mobileView .morgan-mobile-vector {
        margin-top: -33%;
    }
    
    #mobileView .dots-grid-top-left {
        margin-left: -2%;
        max-width: 100%;
    }
    #mobileView .dots-grid-right {
        max-width: 100%;
    }
}

@media screen and (min-width: 800px) and (max-width: 820px) 
{.morgan-desktop-icon {
    margin-left: 14.5%;
    }

}
@media screen and (min-width: 820px) and (max-width: 912px) 
{.morgan-desktop-icon {
    margin-left: 14%;
    }

}

@media screen and (min-width: 912px) and (max-width: 991px) 
{.morgan-desktop-icon {
    margin-left: 12.5%;
    }

}