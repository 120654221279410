.header-container {
  font-size: 16px;
  font-family: "Montserrat Alternates" !important;
  text-align: center;
  vertical-align: middle;
}

.headerlogo {
  max-width: 250px;
  text-align: left;
  padding-right: 0.5rem!important;
}

.alignR {
  padding: 10px;
}

.alignbtn {
  padding-left: 40px;
  align-content: center;
  padding-right: 30px;
}

.navbar-fixed {
  background-color:  #071063;
  /* transition: all .2s ease; */
}

#basic-nav-dropdown {
  padding-top: auto;
  color:#FFFFFF;
}

#color-list-parent {
  padding-top: auto;
  color: #FFFFFF;
}

a.dropdown-item {
  color: #2d388f;
  font-size: 12px;
}

.dblue {
  padding-top: auto;
  background-color:#FFFFFF !important;
  border: none;
  color: #083f88;
  overflow: visible;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 6px 16px;
  box-shadow: 0px 10px 20px rgba(8, 63, 136, 0.2);
  border-radius: 5px;
}


#basic-nav-dropdown:hover {
  color: #00aae4;
  margin-top: 0;
}

#color-list-parent:hover {
  color: #00aae4;
  margin-top: 0;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
}

.modal-content {
  font-family: "Poppins" !important;
}

.modal-header {
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: #00aae4;
  color: #fff;
  font-weight: 500 !important;
}

.borrowerLeadGeneration .blg-col-0 {
  display: none;
}

.borrowerLeadGeneration h6 {
  font-weight: 700;
}

.borrowerLeadGeneration .checkIConsent .form-check-label {
  font-weight: 700;
}

.borrowerLeadGeneration .consent-font {
  font-size: 11px;
}

@media only screen and (max-width: 991px) {
  .headerlogo {
      max-width: 180px;
  }
  .navbar-brand{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .navbar-fixed {
    background:  #020942 !important;
  }
  .navbar-light .navbar-toggler {
    border: none;
    color: #fff;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url('/public/images/mobile/group-1000005470@2x.svg') !important;
  }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0!important;
  }
  #basic-nav-dropdown {
    color: #fff;
  }
  #color-list-parent {
    color: #fff;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1100px) {
  /* .alignR {
    padding-left: 40px;
  } */
  .alignbtn{
    padding-left: 0px;
    padding-right: 0rem;
  }
  .ms-auto{
    margin-left: 0px !important;
  }
  #basic-nav-dropdown, #color-list-parent, .dblue{
    font-size: 12px !important;
  }
 
}

@media only screen and (min-width: 991px) and (max-width: 1100px) {
  .alignR {
    padding-left:18px;
  }
  
 
}
@media only screen and (min-width: 1100px) and (max-width: 1220px) {
  .alignR {
    padding-left: 60px;
  }
}

@media only screen and (min-width: 992px)  {
#navbar{
  background:transparent !important;
    /* z-index: 999;
    box-shadow:none;
    padding-top: 1em;
    height:100px;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms; */
    /* justify-content: center !important; */
}
#navbar.active{ 
background-color: #071063!important;
}
}

.dblue:hover{
  color: #FFFFFF !important;
  background-color: #00aae4!important ;
}



